<template>
    <div class="wrapper">
        <router-view/>
        <b-row class="footer-bar">
            <b-col cols="3">
                <mobile-footer-link :to="{name: 'mobile_app_home'}" icon="clipboard-list"/>
            </b-col>
            <b-col cols="3">
                <mobile-footer-link :to="{name: 'mobile_app_admin'}" icon="chess-queen" :badge="allAdminBadges"/>
            </b-col>
            <b-col cols="3">
                <mobile-footer-link :to="{name: 'mobile_app_slimes'}" icon="paw"/>
            </b-col>
            <b-col cols="3">
                <font-awesome-icon icon="sign-out-alt" @click="signOut"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapState}   from 'vuex';
    import {mapGetters} from "vuex";

    import {updateBadgesBureau} from '@/util/badge';
    import {apiPath}            from '@/util/http';
    import {DEVICE_TYPES}       from '@/util/firebase';
    import alert                from '@/util/alert';
    import {logout, isBureau}   from '@/util/auth';

    const MobileFooterLink = () => import('@/components/mobile_app/MobileFooterLink');

    export default {
        name: "MobileFrame",
        components: {MobileFooterLink},
        computed: {
            ...mapState({mobile: 'mobile'}),
            ...mapGetters({getBadgeOrNull: ['badge/getBadgeOrNull']}),
            allAdminBadges() {
                if (!isBureau())
                    return false;

                return parseInt(this.getBadgeOrNull('utilisateurs') || '0')
                    + parseInt(this.getBadgeOrNull('emprunts') || '0')
                    + parseInt(this.getBadgeOrNull('local') || '0');
            }
        },
        methods: {
            signOut() {
                alert.confirm(
                    'Souhaitez-vous vraiment vous déconnecter ?',
                    () => {
                        alert.loading();
                        logout()
                            .finally(() => {
                                // This 1s delay is really ugly, but without it we have concurrency issues with the
                                // store, even though we use promises everywhere (i.e. after redirection the login
                                // component thinks we're already logged in although we aren't anymore)
                                setTimeout(() => {
                                    alert.stopLoading();
                                    this.$router.push({name: 'mobile_app_login'});
                                }, 1000);
                            })
                    }
                );
            }
        },
        mounted() {
            updateBadgesBureau();

            // Register this device for use in FCM
            if (this.mobile.token) {
                this.axios.post(apiPath('add_webpush_device'), {token: this.mobile.token, type: DEVICE_TYPES.MOBILE})
                    .then(() => this.$store.dispatch('mobile/setToken', null))
                    .catch(() => console.error("Impossible d'enregistrer le token FCM"));
            }

        }
    }
</script>

<style scoped>
    .wrapper {
        padding: 5px 5px 3rem;
    }

    .footer-bar {
        position: fixed;
        bottom: 0;
        margin: 0;
        padding-top: .5rem;
        line-height: 2rem;
        font-size: 2rem;
        width: 100vw;
        height: 3rem;
        border-top: 1px solid #f4f4f4;
        background-color: #fff;
        text-align: center;
    }

    .footer-bar, .footer-bar a {
        color: #525f7f;
    }
</style>